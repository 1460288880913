<template>
    <div class="userinfo">
        <div class="index_head">
            <div class="index_top">
                <div class="left" @click="redirectIndex">
                    <img src="../../../public/logo.png" alt=""/>
                    <p>隆昂烟草图册查询</p>
                </div>
                <div class="right">
                    <div class="user_info">
                        <div class="user_head">
                            <img :src="userInfo.avatar" alt=""/>
                        </div>
                        <div class="user_mes">
                            <h3>{{ userInfo.realname }}</h3>
                            <p>部门：<span>{{ userInfo.department }}</span></p>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="user_info_main">
            <div class="left">
                <div class="box">
                    <div class="item" @click="userinfo">
                        账号信息
                    </div>
                    <div class="item" @click="usersc">
                        我的收藏
                    </div>
                    <div class="item active">
                        修改密码
                    </div>
                    <div class="item" @click="logout">
                        退出登录
                    </div>
                </div>
            </div>
            <div class="right">
                <div class="right_tt">
                    <div class="right_tt_ll">
                        <p>修改密码</p>
                    </div>
                </div>
                <div class="info_bottom">
                    <div class="info_top_word">
                        手机号
                    </div>
                    <div class="info_top_input">
                        <input type="text" disabled="true" :value="userInfo.username" placeholder="18552255658"/>
                    </div>
                </div>
                <div class="info_bottom">
                    <div class="info_top_word">
                        验证码
                    </div>
                    <div class="info_top_input">
                        <input type="text" name="verify_code" v-model="verify_code" placeholder="请输入验证码"/>
                        <span v-if="isSend" @click="sendCode">{{ sendCodeStr }}</span>
                        <span v-else>{{ sendCodeStr }}</span>
                    </div>

                </div>
                <div class="info_bottom">
                    <div class="info_top_word">
                        新密码
                    </div>
                    <div class="info_top_input">
                        <input type="password" name="password" v-model="password" placeholder="请输入新密码"/>
                    </div>
                </div>
                <div class="contr">
                    <p @click="save">确定</p>
                </div>
            </div>
        </div>

    </div>

</template>

<script>
import {hexMD5} from "@/utils/md5";
let timeStop;
export default {
    data() {
        return {
            userInfo: {},
            password: '',
            verify_code: '',
            sendCodeStr: '获取验证码',
            isSend: true,
        }
    },

    mounted() {
        this.getUserInfo();
    },

    methods: {
        /**
         * 个人信息
         */
        getUserInfo() {
            this.$http.get('/user/getUserInfo').then(res => {
                this.userInfo = res.data.data
            });
        },

        sendCode() {
            if (this.isSend) {
                const loading = this.$loading({
                    lock: true,
                    text: '',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                this.isSend = false;
                this.$http.post('/common/sendSms', {
                    phone: this.userInfo.username,
                    sign: hexMD5(this.userInfo.username)
                }).then(res => {
                    loading.close();
                    if (res.data.code == 0) {
                        this.$message({
                            message: "发送成功",
                            type: "success",
                        });
                        this.countDown();
                    } else {
                        this.isSend = true;
                        this.$message({type: 'error', message: res.data.msg})
                    }
                });
            } else {
                return false;
            }
        },
        countDown() {
            var timeo = 60;
            var that = this;
            timeStop = setInterval(function () {
                timeo--;
                if (timeo > 0) {
                    that.isSend = false;
                    that.sendCodeStr = '重新发送' + timeo + 's';
                } else {
                    timeo = 60;
                    that.isSend = true;
                    that.sendCodeStr = '获取验证码';
                    clearInterval(timeStop);
                }
            }, 1000)
        },

        save() {
            var that = this;
            if (this.verify_code.length == 0) {
                this.$message.error('请输入验证码');
                return false;
            }
            if (this.password.length == 0) {
                this.$message.error('请输入新密码');
                return false;
            }
            const loading = this.$loading({
                lock: true,
                text: '',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            this.$http.post('/user/edit', {
                id: that.userInfo.id,
                username: that.userInfo.username,
                password: this.password,
                verify_code: this.verify_code,
            }).then(res => {
                loading.close();
                if (res.data.code == 0) {
                    this.$message.success(res.data.msg);
                    this.password = '';
                    this.verify_code = '';
                    this.sendCodeStr = '获取验证码';
                    this.isSend = true;
                    clearInterval(timeStop);
                    setTimeout(function () {
                        that.getUserInfo();
                    }, 1500);
                } else {
                    this.$message({type: 'error', message: res.data.msg})
                }
            });
        },

        redirectIndex(){
            this.$router.push('/')
        },
        userinfo() {
            this.$router.push('/userinfo')
        },
        usersc() {
            this.$router.push('/usersc')
        },
        logout(){
            this.$messageBox.confirm('确定要退出吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                localStorage.removeItem('access_token')
                this.$message({
                    type: 'success',
                    message: '退出成功!'
                });
                this.$router.push('/login')
            });
        },
    }
};
</script>

<style lang="less" scoped>
.index_head {
    width: 100%;
    background: #fff;

    .index_top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;

        .left {
            display: flex;
            align-items: center;

            img {
                display: block;
                width: 81px;
                margin-right: 24px;
            }

            p {
                font-family: Microsoft YaHei, Microsoft YaHei;
                font-weight: 600;
                font-size: 32px;
                color: #231815;
                line-height: 48px;
            }
        }

        .right {
            display: flex;
            align-items: center;

            .user_info {
                display: flex;
                align-items: center;
                position: relative;
                cursor: pointer;

                .user_head {
                    img {
                        display: block;
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        margin-right: 8px;
                    }
                }

                .user_mes {
                    h3 {
                        font-family: Microsoft YaHei, Microsoft YaHei;
                        font-weight: 600;
                        font-size: 14px;
                        color: #231815;
                        line-height: 22px;
                    }

                    p {
                        font-family: Microsoft YaHei, Microsoft YaHei;
                        font-weight: 400;
                        font-size: 12px;
                        color: #8D8D8D;
                        line-height: 18px;
                        display: flex;
                        align-items: center;

                        span {
                            color: #443B39;
                        }
                    }
                }

            }
        }
    }


}

.user_info_main {
    width: 60%;
    margin: 0 auto;
    margin-top: 26px;
    display: flex;
    justify-content: space-between;

    .left {
        width: 200px;
        margin-right: 30px;

        .box {
            background: #FFFFFF;
            border-radius: 16px 16px 16px 16px;
            padding: 18px 0;

            .item {
                font-family: Microsoft YaHei, Microsoft YaHei;
                font-weight: 400;
                font-size: 16px;
                color: #8D8D8D;
                line-height: 32px;
                margin-bottom: 8px;
                padding-left: 24px;
                cursor: pointer;
            }

            .item.active {
                border-left: 5px solid #EB9114;
            }

            .item:last-child {
                margin-bottom: 0;
            }
        }

    }

    .right {
        flex: 1;
        background: #FFFFFF;
        border-radius: 8px 8px 8px 8px;
        padding: 30px;
        box-sizing: border-box;

        .right_tt {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 21px;

            .right_tt_ll {
                p {
                    font-family: Microsoft YaHei, Microsoft YaHei;
                    font-weight: 400;
                    font-size: 16px;
                    color: #231815;
                    line-height: 34px;
                }

            }
        }

        .info_bottom {
            margin-bottom: 24px;

            .info_top_word {
                font-family: Microsoft YaHei, Microsoft YaHei;
                font-weight: 400;
                font-size: 14px;
                color: #231815;
                line-height: 24px;
                margin-bottom: 12px;
            }

            .info_top_input {
                position: relative;
                span{
                    position: absolute;
                    right: 20px;
                    top: 12px;
                    z-index: 2;
                    color: #fff;
                    background: rgb(235, 145, 20);
                    padding: 4px 10px;
                    border-radius: 5px;
                }
                input {
                    display: block;
                    width: 100%;
                    padding: 12px;
                    border-radius: 8px 8px 8px 8px;
                    border: 1px solid #DCDCDC;
                    font-family: Microsoft YaHei, Microsoft YaHei;
                    font-weight: 400;
                    font-size: 14px;
                    color: #333;
                    line-height: 22px;
                    box-sizing: border-box;
                }

                select {
                    display: block;
                    width: 100%;
                    padding: 12px;
                    border-radius: 8px 8px 8px 8px;
                    border: 1px solid #DCDCDC;
                    font-family: Microsoft YaHei, Microsoft YaHei;
                    font-weight: 400;
                    font-size: 14px;
                    color: #333;
                    line-height: 22px;
                    box-sizing: border-box;
                }
            }
        }

        .contr {
            display: flex;
            align-items: center;
            justify-content: center;

            p {
                width: 200px;
                height: 40px;
                border-radius: 3px 3px 3px 3px;
                border: 1px solid #C7C7C7;
                text-align: center;
                font-family: Microsoft YaHei, Microsoft YaHei;
                font-weight: 400;
                font-size: 14px;
                color: #443B39;
                line-height: 40px;
                margin: 0 10px;
                cursor: pointer;
                box-sizing: border-box;
            }

            p:last-child {
                background: #EB9114;
                border: 1px solid #EB9114;
                color: #fff;
            }
        }
    }
}
</style>
