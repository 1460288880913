import { render, staticRenderFns } from "./userpassword.vue?vue&type=template&id=bd039394&scoped=true&"
import script from "./userpassword.vue?vue&type=script&lang=js&"
export * from "./userpassword.vue?vue&type=script&lang=js&"
import style0 from "./userpassword.vue?vue&type=style&index=0&id=bd039394&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bd039394",
  null
  
)

export default component.exports